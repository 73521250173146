import styled from "styled-components";

import ReservoirImage from "assets/Reservoir.svg";
import ReservoirErrorImage from "assets/ReservoirError.svg";

import Units from "../Units/Units";
import WaterScale from "../WaterScale/WaterScale";
const Container = styled.div`
  position: absolute;
  left: 15.5rem;
  top: 6rem;
  z-index: 10;
`;
const Heading = styled.h1`
  position: absolute;
  bottom: 0.5rem;
  font-size: 1.2rem;
  right: 50%;
  left: 50%;
  transform: translate(-50%);
  width: fit-content;
  color: #4f4e4e;
`;
const Reservoir: React.FC<{
  reservoirLowLimit: string;
  reservoirHighLimit: string;
  reservoirLowProtectionLimit: string;
  capacity: string;
  level: string;
}> = ({
  reservoirLowProtectionLimit,
  capacity,
  level,
  reservoirHighLimit,
  reservoirLowLimit,
}) => {
  let levelNumber = 0;
  if (level) {
    levelNumber = parseInt(level);
  }

  const isError = (levelNumber / parseInt(capacity as string)) * 100 < 30;

  return (
    <Container>
      <img src={!isError ? ReservoirImage : ReservoirErrorImage} alt="" />
      <WaterScale
        reservoirHighLimit={reservoirHighLimit}
        reservoirLowLimit={reservoirLowLimit}
        low={reservoirLowProtectionLimit}
        percentage={(levelNumber / parseInt(capacity as string)) * 100}
        capacity={capacity}
        left="1.7rem"
      />
      <Units
        error={isError}
        top={2.6}
        right={3}
        unit="m"
        heading="Воден стълб"
        value={level}
      />
      <Heading>Резервоар</Heading>
    </Container>
  );
};

export default Reservoir;

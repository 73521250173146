import { Spin } from "antd";

import { useAuth } from "auth/useAuth";
import MainDashboard from "screens/Private/MainDashboard";

const MainDashboardContainer: React.FC = () => {
  const { locationId } = useAuth();

  return <>{locationId ? <MainDashboard /> : <Spin />}</>;
};

export default MainDashboardContainer;

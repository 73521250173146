import { Dispatch, SetStateAction } from "react";

import {
  DownOutlined,
  GlobalOutlined,
  ProfileOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Drawer, Dropdown, Flex, MenuProps, Space } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useAuth } from "auth/useAuth";
import { useGetClientQuery } from "graphql/main";
import i18n from "i18n";
import { logout } from "utils/authorizationUtils";

const LogoutButton = styled(Button)`
  margin-left: auto;
  font-size: 0.875rem;
  font-weight: bold;
  background: lightgray;
  color: rgba(0, 0, 0, 0.88);
  &:hover {
    color: #9be3ff;
  }
`;
const RightSideBar: React.FC<{
  isRightSideBarOpen: boolean;
  setIsRightSideBarOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ isRightSideBarOpen, setIsRightSideBarOpen }) => {
  const { t } = useTranslation();
  const { role } = useAuth();
  const allLanguages = Object.keys(i18n.services.resourceStore.data);
  const currentLanguage = i18n.resolvedLanguage || "en";

  const { data: userData } = useGetClientQuery();
  const items: MenuProps["items"] = allLanguages.map((lang) => ({
    disabled: lang === currentLanguage,
    key: lang,
    label: t(lang),
    onClick: () => i18n.changeLanguage(lang),
  }));
  const sideBarCloseHandler = () => {
    setIsRightSideBarOpen((prevState) => !prevState);
  };
  console.log(userData);

  return (
    <>
      <Drawer
        size="default"
        width={250}
        onClose={sideBarCloseHandler}
        open={isRightSideBarOpen}
      >
        <Flex justify="space-between">
          {userData && role === "Client" ? (
            <p>Client: {userData.client.clientName}</p>
          ) : userData && role === "Admin" ? (
            <p>User: {userData.client.userName}</p>
          ) : null}
          <UserOutlined
            width={200}
            style={{ fontSize: "300%", marginRight: "1.2rem" }}
          />
        </Flex>
        {/*{userData.client.clientImageUrl ? (*/}
        {/*  <img*/}
        {/*    src={*/}
        {/*      userData.client.clientImageUrl*/}
        {/*        ? `${userData.client.clientImageUrl}`*/}
        {/*        : ""*/}
        {/*    }*/}
        {/*    alt="client-image"*/}
        {/*  />*/}
        {/*) : (*/}
        {/*  <ProfileOutlined width={100} />*/}
        {/*)}*/}
        <Flex justify="space-between">
          <Dropdown menu={{ items }}>
            <Space style={{ fontSize: "1rem" }}>
              <GlobalOutlined />
              <span>{t(currentLanguage)}</span>
              <DownOutlined />
            </Space>
          </Dropdown>
          <LogoutButton onClick={() => logout()}>{t("logout")}</LogoutButton>
        </Flex>
      </Drawer>
    </>
  );
};

export default RightSideBar;

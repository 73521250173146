import styled from "styled-components";

import VoltageSvg from "assets/Voltage.svg";
import VoltageError from "assets/VoltageError.svg";
import VoltageInner from "assets/VoltageInner.svg";

import Units from "../Units/Units";

const Container = styled.div`
  position: absolute;
  left: 0.8rem;
  top: 6rem;
`;

const VoltageImg = styled.img`
  position: absolute;
  left: 1rem;
  top: 0.9rem;
`;

const Voltage: React.FC<{
  voltageHighValue: string | null | undefined;
  voltage: string | null | undefined;
  voltageLowValue: string | null | undefined;
}> = ({ voltage, voltageHighValue, voltageLowValue }) => {
  const isError =
    parseInt(voltage as string) > parseInt(voltageHighValue as string) ||
    parseInt(voltage as string) < parseInt(voltageLowValue as string);

  return (
    <Container>
      <img src={isError ? VoltageError : VoltageSvg} />
      <VoltageImg src={VoltageInner} />
      <Units
        error={isError}
        right={2}
        value={voltage}
        heading="Напрежение"
        unit={"V"}
      />
    </Container>
  );
};

export default Voltage;

import styled from "styled-components";

import GroundPumpSvg from "assets/GroundPump.svg";
import GroundPumpErrorSvg from "assets/GroundPumpError.svg";

import Units from "../Units/Units";

const Container = styled.div`
  position: absolute;
  top: 23.35rem;
  left: 31.6rem;
  z-index: 7;
  scale: 0.9;
`;
const StyledImg = styled.img`
  position: absolute;
  z-index: 5;
`;
const ErrorImage = styled(StyledImg)`
  filter: drop-shadow(4px 4px 4px #f65623);
`;
const Heading = styled.h1`
  position: absolute;
  top: 5.5rem;
  font-size: 1.2rem;
  white-space: nowrap;
  width: fit-content;
  color: white;
`;

const GroundPump: React.FC<{
  amperage: string;
  pumpCurrentMeasurement: string;
  pumpEmergencyStop: string;
  pumpMotorProtection: string;
}> = ({
  pumpCurrentMeasurement,
  pumpEmergencyStop,
  pumpMotorProtection,
  amperage,
}) => {
  const isError = false;
  const isPumpError =
    pumpCurrentMeasurement !== "OK" ||
    pumpEmergencyStop !== "NO" ||
    pumpMotorProtection !== "OK";

  return (
    <Container>
      {!isError ? (
        <StyledImg src={GroundPumpSvg} />
      ) : (
        <ErrorImage src={GroundPumpErrorSvg} />
      )}
      <Units
        top={1.3}
        right={-9}
        heading="Сила на тока"
        value={amperage}
        unit="A"
        error={isError}
      />
      <Heading>Наземна помпа</Heading>
    </Container>
  );
};

export default GroundPump;

import { useEffect, useState } from "react";

import Point from "assets/Ellipse 7.svg";
import VesselError from "assets/Rectangle 481 Error.svg";
import Vessel from "assets/Rectangle 481.svg";
import Triangle from "assets/Triangle_blue.svg";

import {
  Container,
  VesselErrorImg,
  VesselImg,
  VesselHeading,
  InvisibleCircle,
  Arrow,
  PointImage,
  MarkerZero,
  MarkerOne,
  MarkerTwo,
  MarkerThree,
  MarkerFour,
  MarkerFive,
  MarkerSix,
  MarkerSeven,
  MarkerEight,
  MarkerNine,
  MarkerTen,
  NumberZero,
  NumberFive,
  NumberTen,
  NewBarometerOuter,
  NewBarometerWhite,
  NewBarometerInner,
  InnerWhite,
  InnerBluish,
  OuterWhite,
} from "../Barometer/styles";
import Units from "../Units/Units";
const Barometer: React.FC<{
  pressureMeasurement: string;
  pressureHighLimit: string;
  pressureLowLimit: string;
  barsData: string;
}> = ({
  barsData,
  pressureLowLimit,
  pressureHighLimit,
  pressureMeasurement,
}) => {
  let barsDataNumber = 0;
  if (barsData) {
    barsDataNumber = parseFloat(barsData);
  }
  const [bars, setBars] = useState(36);

  const isError =
    barsData > pressureHighLimit ||
    barsData < pressureLowLimit ||
    pressureMeasurement !== "OK";
  const percentageHigh = 8.4 * parseFloat(pressureHighLimit) + 8;
  const percentageLow = 8.4 * parseFloat(pressureLowLimit) + 8;

  useEffect(() => {
    setBars(barsDataNumber * 30 + 31);
  }, [barsData]);

  return (
    <Container>
      <OuterWhite>
        <NewBarometerOuter
          $maxDanger={percentageHigh}
          $minDanger={percentageLow}
        >
          <NewBarometerWhite>
            <NewBarometerInner
              $maxDanger={percentageHigh}
              $minDanger={percentageLow}
            >
              <InnerWhite>
                <InnerBluish />
              </InnerWhite>
            </NewBarometerInner>
          </NewBarometerWhite>
        </NewBarometerOuter>
      </OuterWhite>
      {isError ? (
        <VesselErrorImg src={VesselError} />
      ) : (
        <VesselImg src={Vessel} />
      )}
      <VesselHeading>Разширителен съд</VesselHeading>

      <InvisibleCircle>
        <Arrow style={{ transform: `rotate(${bars}deg)` }}>
          <PointImage src={Triangle} />
        </Arrow>
        <MarkerZero />
        <MarkerOne />
        <MarkerTwo />
        <MarkerThree />
        <MarkerFour />
        <MarkerFive />
        <MarkerSix />
        <MarkerSeven />
        <MarkerEight />
        <MarkerNine />
        <MarkerTen />
      </InvisibleCircle>
      <NumberZero>0.0</NumberZero>
      <NumberFive>5</NumberFive>
      <NumberTen>10</NumberTen>
      <Units
        error={isError}
        top={4.1}
        right={3}
        unit="bar"
        heading="Налягане"
        value={barsData}
      />
    </Container>
  );
};

export default Barometer;

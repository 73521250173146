import styled from "styled-components";

import levelWhite from "assets/Rectangle 491.svg";
import levelBlue from "assets/Rectangle 493.svg";

const LevelContainer = styled.div`
  position: absolute;
  left: 0.5rem;
  top: -0.35rem;
  z-index: 4;
  height: 10rem;
  width: 1.4rem;
`;
const LevelWhiteImage = styled.img`
  position: absolute;
`;

const LevelBlueImage = styled.img`
  position: absolute;
  left: 0.25rem;
  top: 0.25rem;
  z-index: 5;
`;

const Level = styled.div<{ height: number }>`
  position: absolute;
  height: ${(props) => (props ? props.height + "rem" : "8.3rem")};
  width: 0.5rem;
  bottom: 1rem;
  left: 0.4rem;
  background-color: #0161ad;
  z-index: 5;
  &:before {
    content: "";
    width: 0.5rem;
    height: 0.5rem;
    position: absolute;
    background-color: #0161ad;
    border-radius: 50%;
    top: -0.25rem;
  }
  &:after {
    content: "";
    width: 0.5rem;
    height: 0.5rem;
    position: absolute;
    background-color: #0161ad;
    border-radius: 50%;
    bottom: -0.25rem;
  }
`;

const CriticalLevelMark = styled.div<{ $lowLimit: number }>`
  position: absolute;
  height: 0.2rem;
  width: 0.5rem;
  background-color: #f65623;
  border-top: 1px;
  border-bottom: 1px;
  border-left: 1px;
  border-right: 1px;
  border-color: #d7f1ff;
  border-style: solid;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  left: 0.4rem;
  bottom: ${(props) => `${props.$lowLimit}rem`};
  z-index: 6;
`;
const LimitMark = styled.div<{ $limit: number; $height: number }>`
  position: absolute;
  height: ${(props) => `${props.$height}rem`};
  width: 0.2rem;
  background-color: #a3dc81;
  margin: 0;
  padding: 0;
  left: 0.1rem;
  bottom: ${(props) => `${props.$limit}rem`};
  z-index: 6;
`;
const ScaleContainer = styled.div`
  position: absolute;
  top: 3.4rem;
  left: 2.7rem;
`;

const Scale = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 8.9rem;
  z-index: 4;
  width: 1rem;
`;

const Division = styled.div`
  background-color: #7c7b7b;
  width: 2.05px;
  height: 1px;
`;

const ScaleNumbers = styled.div`
  position: absolute;
  top: -0.5rem;
  left: -0.7rem;
  font-size: 0.6rem;
  height: 10.9rem;
  z-index: 4;
  width: 0.5rem;
  color: #7c7b7b;
`;

const One = styled.p`
  position: absolute;
  top: -0.5rem;
  right: 0;
`;
const Two = styled.p`
  position: absolute;
  top: 3.9rem;
  right: 0;
`;

const Three = styled.p`
  position: absolute;
  top: 8.3rem;
  right: 0;
`;

const WaterScale: React.FC<{
  reservoirHighLimit?: string;
  reservoirLowLimit?: string;
  low: string;
  top?: string;
  left?: string;
  capacity: string;
  divisions?: number;
  percentage: number;
}> = ({
  reservoirHighLimit,
  reservoirLowLimit,
  low,
  top,
  left,
  capacity,
  divisions = 10,
  percentage,
}) => {
  const divisionsArray: number[] = [0];
  const fullHeightInRem = 8.3;
  const levelHeight = (percentage * fullHeightInRem) / 100;
  const upperLimit = divisions;
  const percentageCoefficient = 100 / parseInt(capacity);

  const lowProtectionLimitPercentage =
    (parseFloat(low) * percentageCoefficient) / 100;
  const lowLimit = fullHeightInRem * lowProtectionLimitPercentage + 1;

  for (let i = 1; i <= upperLimit; i++) {
    divisionsArray.push(i);
  }

  let lowLimitPercentageInRem = null;
  let highLimitPercentageInRem = null;
  let limitHeight = null;
  if (reservoirHighLimit && reservoirLowLimit) {
    const lowLimitPercentage =
      (parseFloat(reservoirLowLimit) * percentageCoefficient) / 100;
    const highLimitPercentage =
      (parseFloat(reservoirHighLimit) * percentageCoefficient) / 100;
    lowLimitPercentageInRem = fullHeightInRem * lowLimitPercentage + 1;
    highLimitPercentageInRem = fullHeightInRem * highLimitPercentage + 1;

    limitHeight = highLimitPercentageInRem - lowLimitPercentageInRem;
  }

  return (
    <ScaleContainer style={{ left, top }}>
      <LevelContainer>
        <LevelWhiteImage src={levelWhite} alt="" />
        <LevelBlueImage src={levelBlue} alt="" />
        <Level height={levelHeight} />
        <CriticalLevelMark $lowLimit={lowLimit} />
        {reservoirLowLimit && lowLimitPercentageInRem && limitHeight && (
          <LimitMark $height={limitHeight} $limit={lowLimitPercentageInRem} />
        )}
      </LevelContainer>
      <Scale>
        {divisionsArray.map((elem) => {
          return (
            <Division
              style={
                elem === 0 || elem === 5 || elem === 10
                  ? { width: "3.7px" }
                  : {}
              }
              key={elem}
            />
          );
        })}
      </Scale>
      <ScaleNumbers>
        {capacity && (
          <>
            <One>
              {parseFloat(capacity) < 10
                ? parseFloat(capacity).toFixed(1)
                : parseFloat(capacity)}
            </One>
            <Two>{parseInt(capacity) / 2}</Two>
            <Three>0</Three>
          </>
        )}
      </ScaleNumbers>
    </ScaleContainer>
  );
};

export default WaterScale;

import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { Select, Drawer, Flex, Spin } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useAuth } from "auth/useAuth";
import { getUserRole } from "utils/authorizationUtils";

import {
  useGetLocationsLazyQuery,
  useGetClientsQuery,
  useGetClientQuery,
} from "../../graphql/main";

const StyledForm = styled.div`
  margin: 1rem;
`;

const StyledDrawer = styled(Drawer)<{
  $isMobile: boolean;
  $isPortrait: boolean;
}>`
  & > * {
    rotate: ${(props) => (props.$isMobile && props.$isPortrait ? "90deg" : "")};
  }
`;

const LeftSideBar: React.FC<{
  isPortrait: boolean;
  isMobile: boolean;
  isLeftSideBarOpen: boolean;
  setIsLeftSideBarOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ isPortrait, isMobile, isLeftSideBarOpen, setIsLeftSideBarOpen }) => {
  const { clientId, setClientId, setLocationId, locationId, role } = useAuth();
  const [isFirstDropDownOpen, setIsFirstDropDownOpen] = useState(false);
  const [isSecondDropDownOpen, setIsSecondDropDownOpen] = useState(false);
  const { setRole } = useAuth();
  const { t } = useTranslation();
  const { data: userData } = useGetClientQuery({
    onCompleted: () => {
      getUserRole() && setRole(getUserRole());
    },
  });
  const { data: clientsData } = useGetClientsQuery({
    onCompleted: (data) => {
      setClientId(data.clients[0]?.id);
    },
  });

  const [getLocations, { data: locationsData }] = useGetLocationsLazyQuery({
    onCompleted: (data) => {
      setLocationId(data?.locations[0]?.id);
    },
  });
  const sideBarCloseHandler = () => {
    setIsLeftSideBarOpen((prevState) => !prevState);
  };

  const locationChangeHandler = async (e: any) => {
    if (role === "Client") {
      setLocationId(e);
    } else {
      await getLocations({ variables: { clientId: e } });
      setClientId(e);
    }
  };

  const adminClientLocationChangeHandler = (e: string) => {
    setLocationId(e);
  };
  const drawerClickHandler = () => {
    isFirstDropDownOpen && setIsFirstDropDownOpen(false);
    isSecondDropDownOpen && setIsSecondDropDownOpen(false);
  };

  useEffect(() => {
    clientsData?.clients.length &&
      getLocations({ variables: { clientId: clientsData?.clients[0].id } });
  }, [clientsData]);

  return (
    <Drawer
      headerStyle={{ borderBottom: "none" }}
      // closeIcon={!(isPortrait && isMobile)}
      size="default"
      width={300}
      // placement={isPortrait && isMobile ? "top" : "left"}
      placement="left"
      open={isLeftSideBarOpen}
      onClose={sideBarCloseHandler}
    >
      <Flex
        onClick={drawerClickHandler}
        vertical
        align={"center"}
        justify={"center"}
      >
        <StyledForm>
          {role && (clientsData || userData) ? (
            <Flex vertical>
              {role === "Client" ? (
                <label>{t("locations")}:</label>
              ) : (
                <label>{t("clients")}:</label>
              )}
              <Select
                onChange={(e) => locationChangeHandler(e)}
                id="location"
                style={{ marginTop: "0.5rem", width: "10rem" }}
                value={role === "Client" ? locationId : clientId}
              >
                {userData && role === "Client"
                  ? locationsData?.locations?.map((elem) => {
                      return (
                        <Select.Option
                          key={elem.id}
                          id={elem.id}
                          value={elem.id}
                        >
                          {elem.name}
                        </Select.Option>
                      );
                    })
                  : clientsData?.clients.map((elem) => {
                      return (
                        <Select.Option
                          key={elem.id}
                          id={elem.id}
                          value={elem.id}
                        >
                          {elem.name}
                        </Select.Option>
                      );
                    })}
              </Select>
            </Flex>
          ) : (
            <Spin />
          )}

          {role === "Admin" && (
            <Flex vertical style={{ marginTop: "2rem" }}>
              <label>{t("locations")}:</label>
              <Select
                style={{ marginTop: "0.5rem", width: "10rem" }}
                onChange={(e) => adminClientLocationChangeHandler(e)}
                id="locationAdmin"
                value={locationId}
              >
                {locationsData?.locations.map((elem) => {
                  return (
                    <Select.Option key={elem.id} id={elem.id} value={elem.id}>
                      {elem.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Flex>
          )}
        </StyledForm>
      </Flex>
    </Drawer>
  );
};

export default LeftSideBar;

import { FC } from "react";

import { Route, Routes, Navigate } from "react-router-dom";

import MainDashboardContainer from "screens/Private/MainDashboardContainer";

import AllRoutes from "./AllRoutes";
import Login from "./Public/Login";
import Alarms from "../components/Alarms/Alarms";
const Routing: FC<{ isPortrait: boolean }> = ({ isPortrait }) => {
  return (
    <Routes>
      <Route path="/*" element={<Navigate to="/main-dashboard" replace />} />
      <Route path={AllRoutes.Login} element={<Login />} />
      <Route
        path={AllRoutes.Alarms}
        element={<Alarms isPortrait={isPortrait} />}
      />
      <Route
        path={AllRoutes.MainDashboard}
        element={<MainDashboardContainer />}
      />
    </Routes>
  );
};

export default Routing;

import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";

import { jwtDecode, JwtPayload } from "jwt-decode";

import { getToken, isLoggedIn } from "utils/authorizationUtils";

type ContextState = {
  token: string | null;
  setToken: React.Dispatch<React.SetStateAction<string | null>>;
  clearUserData: () => void;
  setRole: (role: ContextState["role"]) => void;
  role: string | null;
  locationId: string;
  setLocationId: Dispatch<SetStateAction<string>>;
  clientId?: string;
  setClientId: Dispatch<SetStateAction<string | undefined>>;
};

type Props = {
  children: React.ReactNode;
};

const AuthContext = createContext({} as ContextState);

function AuthProvider({ ...props }: Props) {
  const [token, setToken] = useState<ContextState["token"]>(null);
  const [role, setRole] = useState<ContextState["role"]>(null);
  const [locationId, setLocationId] = useState<string>("");
  const [clientId, setClientId] = useState<string | undefined>();

  const getUserRole = () => {
    if (token !== "") {
      const token = getToken();

      return jwtDecode<JwtPayload>(token)[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role" as keyof JwtPayload
      ]!.toString();
    }

    return null;
  };

  useEffect(() => {
    if (token) {
      setRole(getUserRole());
    }
  }, [token, role]);

  useEffect(() => {
    const tokenFromStore = getToken();
    if (isLoggedIn() && !token && tokenFromStore) {
      setToken(getToken());
    }
  }, [token]);

  const clearUserData = () => {
    setToken(null);
  };

  const value = {
    clearUserData,
    clientId,
    locationId,
    role,
    setClientId,
    setLocationId,
    setRole,
    setToken,
    token,
  };

  return <AuthContext.Provider value={value} {...props} />;
}

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useUserContext must be used within a UserProvider`);
  }

  return context;
}

export { AuthProvider, useAuth };

import { useCallback, useState } from "react";

import { Flex } from "antd";
import { useTranslation } from "react-i18next";

import { useAuth } from "auth/useAuth";
import {
  AlarmDescriptionDto,
  useGetAlarmsHistoryQuery,
  useGetAlarmsQuery,
} from "graphql/main";

import {
  StyledButton,
  Container,
  Heading,
  AlarmsContainer,
  Alarm,
  AlarmDate,
  AlarmTime,
  AlarmName,
} from "./styles";
const NUMBER_OF_ALARMS_TO_SHOW = 10;
const Alarms: React.FC<{ isPortrait: boolean }> = ({ isPortrait }) => {
  const [isHistoryActive, setIsHistoryActive] = useState(false);
  const { t } = useTranslation();
  const { locationId } = useAuth();
  const { data: data } = useGetAlarmsQuery({
    variables: { locationId: locationId },
  });
  const { data: history } = useGetAlarmsHistoryQuery({
    variables: { locationId: locationId },
  });
  const alarms =
    data?.alarms.length && data?.alarms.length > NUMBER_OF_ALARMS_TO_SHOW
      ? data?.alarms.slice(0, NUMBER_OF_ALARMS_TO_SHOW)
      : data?.alarms;
  const extractAlarmDescription = useCallback(
    (data?: AlarmDescriptionDto) => {
      if (!data) return "";
      const descriptionArguments = data?.descriptionArguments;

      return t(data?.descriptionCode, {
        "0": descriptionArguments?.length ? descriptionArguments[0] : "",
        "1":
          descriptionArguments?.length && descriptionArguments?.length > 0
            ? descriptionArguments[1]
            : "",
        "2":
          descriptionArguments?.length && descriptionArguments?.length > 1
            ? descriptionArguments[2]
            : "",
      });
    },
    [t],
  );
  const alarmsHistory =
    history?.alarmsHistory.length &&
    history?.alarmsHistory.length > NUMBER_OF_ALARMS_TO_SHOW
      ? history?.alarmsHistory.slice(0, NUMBER_OF_ALARMS_TO_SHOW)
      : history?.alarmsHistory;
  const extractAlarmHistoryDescription = useCallback(
    (history?: AlarmDescriptionDto) => {
      if (!history) return "";
      const descriptionArguments = history?.descriptionArguments;

      return t(history?.descriptionCode, {
        "0": descriptionArguments?.length ? descriptionArguments[0] : "",
        "1":
          descriptionArguments?.length && descriptionArguments?.length > 0
            ? descriptionArguments[1]
            : "",
        "2":
          descriptionArguments?.length && descriptionArguments?.length > 1
            ? descriptionArguments[2]
            : "",
      });
    },
    [t],
  );
  function formatTimestampTime(timestamp: string) {
    const date = new Date(timestamp);

    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
  }
  function formatTimestampDate(timestamp: string) {
    const date = new Date(timestamp);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${day}.${month}.${year}`;
  }
  const historyToggle = () => {
    setIsHistoryActive((prevState) => !prevState);
  };

  return (
    <Container>
      <Flex justify={"space-between"}>
        <Heading>Активни аларми</Heading>
        <StyledButton $isActive={isHistoryActive} onClick={historyToggle}>
          Журнал
        </StyledButton>
      </Flex>
      <AlarmsContainer>
        {isHistoryActive
          ? alarmsHistory?.map((elem) => {
              return (
                <Alarm key={elem.id}>
                  <AlarmName>
                    {extractAlarmHistoryDescription(elem.description)}
                  </AlarmName>
                  <AlarmTime>{formatTimestampTime(elem.timestamp)}</AlarmTime>
                  <AlarmDate>{formatTimestampDate(elem.timestamp)}</AlarmDate>
                </Alarm>
              );
            })
          : alarms?.map((elem) => {
              return (
                <Alarm key={elem.id}>
                  <AlarmName>
                    {extractAlarmDescription(elem.description)}
                  </AlarmName>
                  <AlarmTime>{formatTimestampTime(elem.timestamp)}</AlarmTime>
                  <AlarmDate>{formatTimestampDate(elem.timestamp)}</AlarmDate>
                </Alarm>
              );
            })}
      </AlarmsContainer>
    </Container>
  );
};

export default Alarms;

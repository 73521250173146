import { useEffect, useState } from "react";

import styled from "styled-components";

import Arrow from "assets/Light_Blue_Circle.svg";

const Container = styled.div`
  position: absolute;
  top: 17.5rem;
`;
const Pipe = styled.div`
  position: absolute;
  border: 1px solid #234c69;
  background-color: #a1bfd2;
  overflow: hidden;
`;
const PipeOne = styled(Pipe)`
  height: 2.2rem;
  width: 0.4rem;
  border-top: none;
  z-index: 12;
  top: 0.45rem;
  left: 8.2rem;
`;

const PipeTwo = styled(Pipe)`
  height: 0.4rem;
  width: 5.1rem;
  z-index: 11;
  top: 0rem;
  left: 8.2rem;
  border-top-left-radius: 10px;
  border-right: none;
`;

const PipeThree = styled(Pipe)`
  height: 8rem;
  width: 0.4rem;
  z-index: 10;
  //to do
  top: -7.55rem;
  left: 13.3rem;
  border-bottom-right-radius: 10px;
  border-top: none;
`;
const PipeFour = styled(Pipe)`
  height: 0.4rem;
  width: 12rem;
  z-index: 9;
  top: -8rem;
  left: 13.3rem;
  border-top-left-radius: 10px;
  border-right: none;
`;
const PipeFive = styled(Pipe)`
  height: 8rem;
  width: 0.4rem;
  z-index: 8;
  top: -8rem;
  left: 25.3rem;
  border-top-right-radius: 10px;
  border-bottom: none;
`;
const PipeSix = styled(Pipe)`
  height: 0.4rem;
  width: 3.5rem;
  z-index: 7;
  top: 0;
  left: 25.3rem;
  border-bottom-left-radius: 10px;
  border-right: none;
`;

const PipeSeven = styled(Pipe)`
  height: 8.3rem;
  width: 0.4rem;
  z-index: 6;
  top: 0rem;
  left: 28.8rem;
  border-top-right-radius: 10px;
  border-bottom: none;
`;
const PipeEight = styled(Pipe)`
  height: 0.4rem;
  width: 7.5rem;
  z-index: 5;
  top: 8.3rem;
  left: 28.8rem;
  border-bottom-left-radius: 10px;
  border-right: none;
`;
const PipeNine = styled(Pipe)`
  height: 6rem;
  width: 0.4rem;
  z-index: 4;
  top: 2.75rem;
  left: 36.3rem;
  border-bottom-right-radius: 10px;
  border-top: none;
`;
const PipeForNoSecondPump = styled(Pipe)`
  height: 0.4rem;
  width: 20.6rem;
  z-index: 9;
  top: 0;
  left: 8.2rem;
  border-top-left-radius: 10px;
  border-right: none;
`;
const ArrowContainer = styled.div`
  position: absolute;
  display: flex;
`;
const ArrowContainerForNoSecondPump = styled(ArrowContainer)`
  left: -4rem;
  flex-direction: row;
  top: -0.05rem;

  animation: flowNoPump 2s linear infinite;

  @keyframes flowNoPump {
    0% {
      left: -3.5rem;
    }
    100% {
      left: 0rem;
    }
  }
`;

const ArrowContainerOne = styled(ArrowContainer)`
  left: -0.15rem;
  flex-direction: column;
  top: 0rem;
  animation: flow 2s linear infinite;

  @keyframes flow {
    0% {
      top: 0rem;
    }
    100% {
      top: -3rem;
    }
  }
`;
const ArrowContainerTwo = styled(ArrowContainer)`
  left: -4rem;
  flex-direction: row;
  top: -0.05rem;

  animation: flowTwo 2s linear infinite;

  @keyframes flowTwo {
    0% {
      left: -3.5rem;
    }
    100% {
      left: 0rem;
    }
  }
`;

const ArrowContainerThree = styled(ArrowContainer)`
  left: -0.16rem;
  flex-direction: column;
  top: 0rem;

  animation: flowThree 2s linear infinite;

  @keyframes flowThree {
    0% {
      top: 0rem;
    }
    100% {
      top: -3rem;
    }
  }
`;
const ArrowContainerFour = styled(ArrowContainer)`
  top: -0.05rem;
  flex-direction: row;
  left: -3.5rem;

  animation: flowFour 2s linear infinite;

  @keyframes flowFour {
    0% {
      left: -3.5rem;
    }
    100% {
      left: 0rem;
    }
  }
`;
const ArrowContainerFive = styled(ArrowContainer)`
  top: 0rem;
  flex-direction: column;
  left: -0.17rem;

  animation: flowFive 2s linear infinite;

  @keyframes flowFive {
    0% {
      top: -3rem;
    }
    100% {
      top: 0rem;
    }
  }
`;
const ArrowContainerSix = styled(ArrowContainer)`
  left: -4rem;
  flex-direction: row;
  top: -0.05rem;

  animation: flowSix 2s linear infinite;

  @keyframes flowSix {
    0% {
      left: -3.5rem;
    }
    100% {
      left: 0rem;
    }
  }
`;
const ArrowContainerSeven = styled(ArrowContainer)`
  top: -3rem;
  flex-direction: column;
  left: -0.16rem;
  animation: flowSeven 2s linear infinite;

  @keyframes flowSeven {
    0% {
      top: -3rem;
    }
    100% {
      top: -0rem;
    }
  }
`;
const ArrowContainerEight = styled(ArrowContainer)`
  left: -4rem;
  flex-direction: row;
  top: -0.05rem;

  animation: flowEight 2s linear infinite;

  @keyframes flowEight {
    0% {
      left: -3.5rem;
    }
    100% {
      left: 0rem;
    }
  }
`;
const ArrowContainerNine = styled(ArrowContainer)`
  left: -0.18rem;
  flex-direction: column;
  top: 0;

  animation: flowNine 2s linear infinite;

  @keyframes flowNine {
    0% {
      top: 0rem;
    }
    100% {
      top: -3rem;
    }
  }
`;
const ArrowImg = styled.img<{ $up?: boolean; $down?: boolean }>`
  transform: rotate(
    ${(props) => {
      if (props.$up) {
        return "-90deg";
      } else if (props.$down) {
        return "90deg";
      } else return "";
    }}
  );
  width: 0.7rem;
  height: 0.5rem;
  scale: 0.55;
`;

const Pipes: React.FC<{
  isPump2Present: string | null | undefined;
  pump1contactor: string | null | undefined;
  pump2contactor: string | null | undefined;
}> = ({ pump1contactor, pump2contactor, isPump2Present }) => {
  const [isAnimating1, setIsAnimating1] = useState(true);
  const [isAnimating2, setIsAnimating2] = useState(true);

  useEffect(() => {
    if (isPump2Present === "YES") {
      if (pump1contactor === "OFF") {
        setIsAnimating1(false);
      }
      if (pump2contactor === "OFF") {
        setIsAnimating2(false);
      }
    } else {
      if (pump1contactor === "OFF") {
        setIsAnimating1(false);
        setIsAnimating2(false);
      }
    }
  }, [pump1contactor, pump2contactor, isPump2Present]);

  return (
    <Container>
      <PipeOne>
        {isAnimating1 && (
          <ArrowContainerOne>
            <ArrowImg $up src={Arrow} />
            <ArrowImg $up src={Arrow} />
            <ArrowImg $up src={Arrow} />
            <ArrowImg $up src={Arrow} />
            <ArrowImg $up src={Arrow} />
            <ArrowImg $up src={Arrow} />
            <ArrowImg $up src={Arrow} />
            <ArrowImg $up src={Arrow} />
            <ArrowImg $up src={Arrow} />
            <ArrowImg $up src={Arrow} />
          </ArrowContainerOne>
        )}
      </PipeOne>
      {isPump2Present === "YES" ? (
        <>
          <PipeTwo>
            {isAnimating1 && (
              <ArrowContainerTwo>
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
              </ArrowContainerTwo>
            )}
          </PipeTwo>
          <PipeThree>
            {isAnimating1 && (
              <ArrowContainerThree>
                <ArrowImg $up src={Arrow} />
                <ArrowImg $up src={Arrow} />
                <ArrowImg $up src={Arrow} />
                <ArrowImg $up src={Arrow} />
                <ArrowImg $up src={Arrow} />
                <ArrowImg $up src={Arrow} />
                <ArrowImg $up src={Arrow} />
                <ArrowImg $up src={Arrow} />
                <ArrowImg $up src={Arrow} />
                <ArrowImg $up src={Arrow} />
                <ArrowImg $up src={Arrow} />
                <ArrowImg $up src={Arrow} />
                <ArrowImg $up src={Arrow} />
                <ArrowImg $up src={Arrow} />
                <ArrowImg $up src={Arrow} />
                <ArrowImg $up src={Arrow} />
                <ArrowImg $up src={Arrow} />
                <ArrowImg $up src={Arrow} />
                <ArrowImg $up src={Arrow} />
                <ArrowImg $up src={Arrow} />
                <ArrowImg $up src={Arrow} />
              </ArrowContainerThree>
            )}
          </PipeThree>
          <PipeFour>
            {isAnimating1 && (
              <ArrowContainerFour>
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
              </ArrowContainerFour>
            )}
          </PipeFour>
          <PipeFive>
            {isAnimating1 && (
              <ArrowContainerFive>
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />

                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
                <ArrowImg $down src={Arrow} />
              </ArrowContainerFive>
            )}
          </PipeFive>
          <PipeSix>
            {isAnimating2 && (
              <ArrowContainerSix>
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
              </ArrowContainerSix>
            )}
          </PipeSix>
        </>
      ) : (
        <>
          <PipeForNoSecondPump>
            {isAnimating2 && (
              <ArrowContainerForNoSecondPump>
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />

                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />

                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
                <ArrowImg src={Arrow} />
              </ArrowContainerForNoSecondPump>
            )}
          </PipeForNoSecondPump>
        </>
      )}
      <PipeSeven>
        {isAnimating2 && (
          <ArrowContainerSeven>
            <ArrowImg $down src={Arrow} />
            <ArrowImg $down src={Arrow} />
            <ArrowImg $down src={Arrow} />
            <ArrowImg $down src={Arrow} />
            <ArrowImg $down src={Arrow} />
            <ArrowImg $down src={Arrow} />
            <ArrowImg $down src={Arrow} />
            <ArrowImg $down src={Arrow} />
            <ArrowImg $down src={Arrow} />
            <ArrowImg $down src={Arrow} />
            <ArrowImg $down src={Arrow} />
            <ArrowImg $down src={Arrow} />
            <ArrowImg $down src={Arrow} />
            <ArrowImg $down src={Arrow} />
            <ArrowImg $down src={Arrow} />
            <ArrowImg $down src={Arrow} />
            <ArrowImg $down src={Arrow} />
            <ArrowImg $down src={Arrow} />
            <ArrowImg $down src={Arrow} />
            <ArrowImg $down src={Arrow} />
            <ArrowImg $down src={Arrow} />
            <ArrowImg $down src={Arrow} />
            <ArrowImg $down src={Arrow} />
            <ArrowImg $down src={Arrow} />
          </ArrowContainerSeven>
        )}
      </PipeSeven>
      <PipeEight>
        {isAnimating2 && (
          <ArrowContainerEight>
            <ArrowImg src={Arrow} />
            <ArrowImg src={Arrow} />
            <ArrowImg src={Arrow} />
            <ArrowImg src={Arrow} />
            <ArrowImg src={Arrow} />
            <ArrowImg src={Arrow} />
            <ArrowImg src={Arrow} />
            <ArrowImg src={Arrow} />
            <ArrowImg src={Arrow} />
            <ArrowImg src={Arrow} />
            <ArrowImg src={Arrow} />
            <ArrowImg src={Arrow} />
            <ArrowImg src={Arrow} />
            <ArrowImg src={Arrow} />
            <ArrowImg src={Arrow} />
            <ArrowImg src={Arrow} />
            <ArrowImg src={Arrow} />
            <ArrowImg src={Arrow} />
            <ArrowImg src={Arrow} />
            <ArrowImg src={Arrow} />
            <ArrowImg src={Arrow} />
            <ArrowImg src={Arrow} />
          </ArrowContainerEight>
        )}
      </PipeEight>
      <PipeNine>
        {isAnimating2 && (
          <ArrowContainerNine>
            <ArrowImg $up src={Arrow} />
            <ArrowImg $up src={Arrow} />
            <ArrowImg $up src={Arrow} />
            <ArrowImg $up src={Arrow} />
            <ArrowImg $up src={Arrow} />
            <ArrowImg $up src={Arrow} />
            <ArrowImg $up src={Arrow} />
            <ArrowImg $up src={Arrow} />
            <ArrowImg $up src={Arrow} />
            <ArrowImg $up src={Arrow} />
            <ArrowImg $up src={Arrow} />
            <ArrowImg $up src={Arrow} />
            <ArrowImg $up src={Arrow} />
            <ArrowImg $up src={Arrow} />
            <ArrowImg $up src={Arrow} />
            <ArrowImg $up src={Arrow} />
            <ArrowImg $up src={Arrow} />
          </ArrowContainerNine>
        )}
      </PipeNine>
    </Container>
  );
};

export default Pipes;

import { FC, useState } from "react";

import { Spin } from "antd";
import { jwtDecode, JwtPayload } from "jwt-decode";

import { useAuth } from "auth/useAuth";
import { useGetClientQuery } from "graphql/main";
import { getToken, isValidToken } from "utils/authorizationUtils";

import LeftSideBar from "./LeftSideBar/LeftSideBar";
import NavBar from "./NavBar/NavBar";
import RightSideBar from "./RightSideBar/RightSideBar";
const PageLayout: FC<{
  isPortrait: boolean;
  isMobile: boolean;
  children: React.ReactNode;
}> = ({ children, isPortrait, isMobile }) => {
  const [isRightSideBarOpen, setIsRightSideBarOpen] = useState(false);
  const [isLeftSideBarOpen, setIsLeftSideBarOpen] = useState(false);

  const { token } = useAuth();

  return (
    <>
      {token && isValidToken(token) ? (
        <>
          <LeftSideBar
            isPortrait={isPortrait}
            isMobile={isMobile}
            isLeftSideBarOpen={isLeftSideBarOpen}
            setIsLeftSideBarOpen={setIsLeftSideBarOpen}
          />
          <RightSideBar
            isRightSideBarOpen={isRightSideBarOpen}
            setIsRightSideBarOpen={setIsRightSideBarOpen}
          />
          <NavBar
            setIsRightSideBarOpen={setIsRightSideBarOpen}
            setIsLeftSideBarOpen={setIsLeftSideBarOpen}
          />
        </>
      ) : (
        <Spin />
      )}
      <>{children}</>
    </>
  );
};

export default PageLayout;

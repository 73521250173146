import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { Flex } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";

import AlarmSelected from "assets/Alarm Selected.svg";
import Alarm from "assets/Alarm.svg";
import BurgerIcon from "assets/BurgerIcon.svg";
import CalendarIcon from "assets/CalendarIcon.svg";
import ClockIcon from "assets/ClockIcon.svg";
import ProfileIcon from "assets/ProgileIcon.svg";
import TemperatureIcon from "assets/Temperature Icon.svg";
import AllRoutes from "screens/AllRoutes";

const Container = styled.div`
  position: absolute;
  left: 0;
  width: 100vw;

  height: 3rem;
  background-color: #4794c9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 1rem;
  box-sizing: border-box;

  @media (max-width: 450px) {
    width: 100svh;
  }
`;
const StyledTemperatureIcon = styled.img`
  scale: 0.7;
`;
const SecondsDots = styled.p`
  color: white;
  margin-inline: -0.45rem;
  animation: tik 1s infinite;
  @keyframes tik {
    0% {
      opacity: 0;
    }
    49% {
      opacity: 0;
    }
    51% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
`;
const StyledBorder = styled.img<{ $isShowAlarm: boolean }>`
  border: 2px solid ${(props) => (props.$isShowAlarm ? "#1060B1" : "#9fd5f1")};
  padding: 7px;
  width: 1rem;
  height: 1rem;
  border-radius: 8px;
  margin-top: 4px;
  background-color: ${(props) => (props.$isShowAlarm ? "#b3d8ee" : "#4794c8")};
  cursor: pointer;
`;
const StyledDegrees = styled.p`
  color: white;
`;
const StyledParagraph = styled.p`
  margin-inline: 0.5rem;
  color: white;
`;
const StyledButton = styled.button`
  position: relative;
  border: none;
  background-color: transparent;
  z-index: 19;
`;

const NavBar: React.FC<{
  setIsRightSideBarOpen: Dispatch<SetStateAction<boolean>>;
  setIsLeftSideBarOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ setIsLeftSideBarOpen, setIsRightSideBarOpen }) => {
  const [date, setDate] = useState(new Date());
  const navigate = useNavigate();
  const location = useLocation();

  const isShowAlarm = location.pathname === "/alarms";

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(new Date());
    }, 10000);

    return () => clearInterval(interval);
  }, [setDate, date]);

  let day = date.getDate().toString();
  if (parseInt(day) < 10) {
    day = "0" + day;
  }
  let month: number | string = date.getMonth() + 1;
  const hours = date.getHours();
  let minutes: number | string = date.getMinutes();
  if (month < 10) {
    month = "0" + month.toString();
  }

  if (minutes < 10) {
    minutes = "0" + date.getMinutes();
  }

  const alarmClickHandler = () => {
    navigate(isShowAlarm ? AllRoutes.MainDashboard : AllRoutes.Alarms);
  };

  const burgerClickHandler = () => {
    setIsLeftSideBarOpen((prevState) => !prevState);
  };

  const profileClickHandler = () => {
    setIsRightSideBarOpen((prevState) => !prevState);
  };

  return (
    <Container>
      <StyledButton onClick={burgerClickHandler}>
        <StyledBorder src={BurgerIcon} $isShowAlarm={false} />
      </StyledButton>
      <Flex>
        <StyledParagraph>Дата</StyledParagraph>
        <img src={CalendarIcon} />
        <StyledParagraph>
          {day}.{month}
        </StyledParagraph>
        <StyledParagraph>Час</StyledParagraph>
        <img src={ClockIcon} />
        <StyledParagraph>{hours}</StyledParagraph>
        <SecondsDots>:</SecondsDots>
        <StyledParagraph>{minutes}</StyledParagraph>
      </Flex>
      <Flex>
        <StyledTemperatureIcon src={TemperatureIcon} />
        <StyledDegrees>10 °C</StyledDegrees>
      </Flex>
      <div>
        <StyledButton onClick={alarmClickHandler}>
          <StyledBorder
            $isShowAlarm={isShowAlarm}
            src={isShowAlarm ? AlarmSelected : Alarm}
            alt=""
          />
        </StyledButton>
        <StyledButton onClick={profileClickHandler}>
          <StyledBorder $isShowAlarm={false} src={ProfileIcon} />
        </StyledButton>
      </div>
    </Container>
  );
};

export default NavBar;

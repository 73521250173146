import styled from "styled-components";

import Units from "../Units/Units";

const Container = styled.div`
  position: absolute;
  left: 11rem;
  height: 7rem;
  top: 22rem;
  width: 12rem;
  background-color: #dfeaed;
  border-radius: 5px;
  box-shadow: 2px 4px 4px 0 #00000029;
`;

const StyledHeader = styled.h1`
  position: absolute;
  margin: 0;
  left: 50%;
  right: 50%;
  width: fit-content;
  transform: translate(-50%, -50%);
  top: 5rem;
  white-space: nowrap;
  font-size: 1.2rem;
  color: #4f4e4e;
`;

const PumpInBorehole: React.FC<{
  pumpCurrent: string;
}> = ({ pumpCurrent }) => {
  return (
    <Container>
      <StyledHeader>Помпа в сондаж</StyledHeader>
      <Units
        right={5.5}
        top={1}
        heading="Сила на тока"
        unit="A"
        value={pumpCurrent}
      />
    </Container>
  );
};

export default PumpInBorehole;

import { Container, UpperHalfContainer, Number, Unit, Heading } from "./styles";

const Units: React.FC<{
  top?: number;
  right?: number;
  heading: string;
  unit: string;
  value: string | null | undefined;
  error?: boolean;
}> = ({ heading, unit, value, top, right, error = false }) => {
  return (
    <Container top={top} right={right}>
      <UpperHalfContainer>
        <Number $error={error}>{value}</Number>
        <Unit>{unit}</Unit>
      </UpperHalfContainer>
      <Heading>{heading}</Heading>
    </Container>
  );
};

export default Units;

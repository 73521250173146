import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  UUID: { input: any; output: any; }
};

export type AlarmDescriptionDto = {
  __typename?: 'AlarmDescriptionDto';
  descriptionArguments?: Maybe<Array<Scalars['String']['output']>>;
  descriptionCode: AlarmDescriptionEnum;
};

export enum AlarmDescriptionEnum {
  BoolOkNok = 'BOOL_OK_NOK',
  BoolYesNo = 'BOOL_YES_NO',
  ContractExpired = 'CONTRACT_EXPIRED',
  CountChange = 'COUNT_CHANGE'
}

export type AlarmDto = {
  __typename?: 'AlarmDto';
  description: AlarmDescriptionDto;
  distributionBoardId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  isAcknowledged: Scalars['Boolean']['output'];
  isActive: Scalars['Boolean']['output'];
  status: AlarmStatusEnum;
  timestamp: Scalars['DateTime']['output'];
  type: AlarmTypeEnum;
};

export enum AlarmStatusEnum {
  Acknowledged = 'ACKNOWLEDGED',
  Activated = 'ACTIVATED',
  Deactivated = 'DEACTIVATED'
}

export enum AlarmTypeEnum {
  Bool = 'BOOL',
  CommunicationLost = 'COMMUNICATION_LOST',
  ContractExpired = 'CONTRACT_EXPIRED',
  CountChange = 'COUNT_CHANGE'
}

export type ApplicationError = {
  __typename?: 'ApplicationError';
  code?: Maybe<Scalars['String']['output']>;
  extensions?: Maybe<ApplicationErrorExtensions>;
  message: Scalars['String']['output'];
  path?: Maybe<Array<Scalars['String']['output']>>;
};

export type ApplicationErrorExtensions = {
  __typename?: 'ApplicationErrorExtensions';
  code?: Maybe<Scalars['String']['output']>;
  field?: Maybe<Scalars['String']['output']>;
  property?: Maybe<Scalars['String']['output']>;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export enum CategoryEnum {
  Global = 'GLOBAL',
  Pump1 = 'PUMP1',
  Pump2 = 'PUMP2',
  Setting = 'SETTING',
  Statistic = 'STATISTIC'
}

export type ClientDto = {
  __typename?: 'ClientDto';
  id: Scalars['UUID']['output'];
  imageUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ComponentDto = {
  __typename?: 'ComponentDto';
  category: CategoryEnum;
  componentFormat: ComponentFormatEnum;
  componentName?: Maybe<ComponentLogicalNameEnum>;
  componentType: ComponentTypeEnum;
  description: Scalars['String']['output'];
  slotName: Scalars['String']['output'];
  targetValue?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export enum ComponentFormatEnum {
  AmperageSensor = 'AMPERAGE_SENSOR',
  CommunicationModule = 'COMMUNICATION_MODULE',
  Controller = 'CONTROLLER',
  DigitalInput = 'DIGITAL_INPUT',
  Display = 'DISPLAY',
  Dummy = 'DUMMY',
  LevelSensor1 = 'LEVEL_SENSOR1',
  LevelSensor2 = 'LEVEL_SENSOR2',
  Module = 'MODULE',
  PressureSensor = 'PRESSURE_SENSOR',
  Relay = 'RELAY',
  VirtualFactor1Number = 'VIRTUAL_FACTOR1_NUMBER',
  VirtualFactor6Number = 'VIRTUAL_FACTOR6_NUMBER',
  VirtualFactor10Number = 'VIRTUAL_FACTOR10_NUMBER',
  VirtualFactor100Number = 'VIRTUAL_FACTOR100_NUMBER',
  VirtualOkNokBool = 'VIRTUAL_OK_NOK_BOOL',
  VirtualOnOffBool = 'VIRTUAL_ON_OFF_BOOL',
  VirtualYesNoBool = 'VIRTUAL_YES_NO_BOOL',
  VoltageSensor = 'VOLTAGE_SENSOR'
}

export enum ComponentLogicalNameEnum {
  BoreholeLevel = 'BOREHOLE_LEVEL',
  BoreholeLevelLow = 'BOREHOLE_LEVEL_LOW',
  BoreholeLevelLowProtectionLimit = 'BOREHOLE_LEVEL_LOW_PROTECTION_LIMIT',
  BoreholeLevelMaxLimit = 'BOREHOLE_LEVEL_MAX_LIMIT',
  BoreholeLevelMeasurement = 'BOREHOLE_LEVEL_MEASUREMENT',
  BoreholeLevelMinLimit = 'BOREHOLE_LEVEL_MIN_LIMIT',
  EnableEditing = 'ENABLE_EDITING',
  ModuleForAnalogInputs = 'MODULE_FOR_ANALOG_INPUTS',
  PowerSupplyModuleForAnalogInputs = 'POWER_SUPPLY_MODULE_FOR_ANALOG_INPUTS',
  Pressure = 'PRESSURE',
  PressureHighLimit = 'PRESSURE_HIGH_LIMIT',
  PressureLowLimit = 'PRESSURE_LOW_LIMIT',
  PressureMeasurement = 'PRESSURE_MEASUREMENT',
  Pump1Command = 'PUMP1_COMMAND',
  Pump1Contactor = 'PUMP1_CONTACTOR',
  Pump1Current = 'PUMP1_CURRENT',
  Pump1CurrentMeasurement = 'PUMP1_CURRENT_MEASUREMENT',
  Pump1EmergencyStop = 'PUMP1_EMERGENCY_STOP',
  Pump1MotorProtection = 'PUMP1_MOTOR_PROTECTION',
  Pump2Command = 'PUMP2_COMMAND',
  Pump2Contactor = 'PUMP2_CONTACTOR',
  Pump2Current = 'PUMP2_CURRENT',
  Pump2CurrentMeasurement = 'PUMP2_CURRENT_MEASUREMENT',
  Pump2EmergencyStop = 'PUMP2_EMERGENCY_STOP',
  Pump2MotorProtection = 'PUMP2_MOTOR_PROTECTION',
  Pump2Present = 'PUMP2_PRESENT',
  TankLevel = 'TANK_LEVEL',
  TankLevelHighLimit = 'TANK_LEVEL_HIGH_LIMIT',
  TankLevelLow = 'TANK_LEVEL_LOW',
  TankLevelLowLimit = 'TANK_LEVEL_LOW_LIMIT',
  TankLevelLowProtectionLimit = 'TANK_LEVEL_LOW_PROTECTION_LIMIT',
  TankLevelMaxLimit = 'TANK_LEVEL_MAX_LIMIT',
  TankLevelMeasurement = 'TANK_LEVEL_MEASUREMENT',
  TankLevelMinLimit = 'TANK_LEVEL_MIN_LIMIT',
  Voltage = 'VOLTAGE',
  VoltageHigh = 'VOLTAGE_HIGH',
  VoltageHighLimit = 'VOLTAGE_HIGH_LIMIT',
  VoltageLowLimit = 'VOLTAGE_LOW_LIMIT',
  VoltageMeasurement = 'VOLTAGE_MEASUREMENT'
}

export enum ComponentTypeEnum {
  ReadOnly = 'READ_ONLY',
  ReadWrite = 'READ_WRITE',
  Static = 'STATIC'
}

export type DashboardDto = {
  __typename?: 'DashboardDto';
  components?: Maybe<Array<ComponentDto>>;
  id: Scalars['UUID']['output'];
  sendInterval: Scalars['Int']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export enum ErrorMessages {
  AlarmNotFound = 'ALARM_NOT_FOUND',
  CantDeserializeProcessedData = 'CANT_DESERIALIZE_PROCESSED_DATA',
  ClientNotFound = 'CLIENT_NOT_FOUND',
  DistributionBoardNotFound = 'DISTRIBUTION_BOARD_NOT_FOUND',
  FieldMaxLength = 'FIELD_MAX_LENGTH',
  FieldMinLength = 'FIELD_MIN_LENGTH',
  FieldMustNotBeNull = 'FIELD_MUST_NOT_BE_NULL',
  GreaterThanOrEqual = 'GREATER_THAN_OR_EQUAL',
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  InvalidRefreshToken = 'INVALID_REFRESH_TOKEN',
  LocationNotFound = 'LOCATION_NOT_FOUND',
  NotEmptyField = 'NOT_EMPTY_FIELD',
  NotValidEmail = 'NOT_VALID_EMAIL',
  PasswordExpired = 'PASSWORD_EXPIRED',
  PasswordMinimumLength = 'PASSWORD_MINIMUM_LENGTH',
  UserDoesNotHaveAssignedClient = 'USER_DOES_NOT_HAVE_ASSIGNED_CLIENT',
  UserNotFound = 'USER_NOT_FOUND',
  UserWrongRole = 'USER_WRONG_ROLE',
  WrongEmailOrPassword = 'WRONG_EMAIL_OR_PASSWORD'
}

export type LocationDto = {
  __typename?: 'LocationDto';
  clientId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export type Queries = {
  __typename?: 'Queries';
  alarms: Array<AlarmDto>;
  alarmsHistory: Array<AlarmDto>;
  client: UserClientDto;
  clients: Array<ClientDto>;
  dashboard: DashboardDto;
  locations: Array<LocationDto>;
};


export type QueriesAlarmsArgs = {
  isAcknowledged?: InputMaybe<Scalars['Boolean']['input']>;
  locationId: Scalars['UUID']['input'];
};


export type QueriesAlarmsHistoryArgs = {
  locationId: Scalars['UUID']['input'];
  slotName?: InputMaybe<Scalars['String']['input']>;
};


export type QueriesDashboardArgs = {
  locationId: Scalars['UUID']['input'];
};


export type QueriesLocationsArgs = {
  clientId: Scalars['UUID']['input'];
};

export type UserClientDto = {
  __typename?: 'UserClientDto';
  clientId?: Maybe<Scalars['UUID']['output']>;
  clientImageUrl?: Maybe<Scalars['String']['output']>;
  clientName?: Maybe<Scalars['String']['output']>;
  userEmail: Scalars['String']['output'];
  userId: Scalars['UUID']['output'];
  userName: Scalars['String']['output'];
};

export type GetDashboardQueryVariables = Exact<{
  locationId: Scalars['UUID']['input'];
}>;


export type GetDashboardQuery = { __typename?: 'Queries', dashboard: { __typename?: 'DashboardDto', id: any, sendInterval: number, timestamp: any, components?: Array<{ __typename?: 'ComponentDto', componentFormat: ComponentFormatEnum, componentName?: ComponentLogicalNameEnum | null, componentType: ComponentTypeEnum, description: string, slotName: string, targetValue?: string | null, value?: string | null }> | null } };

export type GetLocationsQueryVariables = Exact<{
  clientId: Scalars['UUID']['input'];
}>;


export type GetLocationsQuery = { __typename?: 'Queries', locations: Array<{ __typename?: 'LocationDto', id: any, clientId: any, name: string }> };

export type GetClientsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetClientsQuery = { __typename?: 'Queries', clients: Array<{ __typename?: 'ClientDto', id: any, name: string, imageUrl: string }> };

export type GetClientQueryVariables = Exact<{ [key: string]: never; }>;


export type GetClientQuery = { __typename?: 'Queries', client: { __typename?: 'UserClientDto', clientId?: any | null, clientImageUrl?: string | null, clientName?: string | null, userEmail: string, userId: any, userName: string } };

export type GetAlarmsHistoryQueryVariables = Exact<{
  locationId: Scalars['UUID']['input'];
  slotName?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetAlarmsHistoryQuery = { __typename?: 'Queries', alarmsHistory: Array<{ __typename?: 'AlarmDto', status: AlarmStatusEnum, id: any, timestamp: any, type: AlarmTypeEnum, description: { __typename?: 'AlarmDescriptionDto', descriptionArguments?: Array<string> | null, descriptionCode: AlarmDescriptionEnum } }> };

export type GetAlarmsQueryVariables = Exact<{
  locationId: Scalars['UUID']['input'];
  isAcknowledged?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetAlarmsQuery = { __typename?: 'Queries', alarms: Array<{ __typename?: 'AlarmDto', id: any, isActive: boolean, timestamp: any, isAcknowledged: boolean, type: AlarmTypeEnum, description: { __typename?: 'AlarmDescriptionDto', descriptionArguments?: Array<string> | null, descriptionCode: AlarmDescriptionEnum } }> };


export const GetDashboardDocument = gql`
    query GetDashboard($locationId: UUID!) {
  dashboard(locationId: $locationId) {
    id
    sendInterval
    timestamp
    components {
      componentFormat
      componentName
      componentType
      description
      slotName
      targetValue
      value
    }
  }
}
    `;

/**
 * __useGetDashboardQuery__
 *
 * To run a query within a React component, call `useGetDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetDashboardQuery(baseOptions: Apollo.QueryHookOptions<GetDashboardQuery, GetDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDashboardQuery, GetDashboardQueryVariables>(GetDashboardDocument, options);
      }
export function useGetDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardQuery, GetDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDashboardQuery, GetDashboardQueryVariables>(GetDashboardDocument, options);
        }
export function useGetDashboardSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetDashboardQuery, GetDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDashboardQuery, GetDashboardQueryVariables>(GetDashboardDocument, options);
        }
export type GetDashboardQueryHookResult = ReturnType<typeof useGetDashboardQuery>;
export type GetDashboardLazyQueryHookResult = ReturnType<typeof useGetDashboardLazyQuery>;
export type GetDashboardSuspenseQueryHookResult = ReturnType<typeof useGetDashboardSuspenseQuery>;
export type GetDashboardQueryResult = Apollo.QueryResult<GetDashboardQuery, GetDashboardQueryVariables>;
export const GetLocationsDocument = gql`
    query GetLocations($clientId: UUID!) {
  locations(clientId: $clientId) {
    id
    clientId
    name
  }
}
    `;

/**
 * __useGetLocationsQuery__
 *
 * To run a query within a React component, call `useGetLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetLocationsQuery(baseOptions: Apollo.QueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, options);
      }
export function useGetLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, options);
        }
export function useGetLocationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, options);
        }
export type GetLocationsQueryHookResult = ReturnType<typeof useGetLocationsQuery>;
export type GetLocationsLazyQueryHookResult = ReturnType<typeof useGetLocationsLazyQuery>;
export type GetLocationsSuspenseQueryHookResult = ReturnType<typeof useGetLocationsSuspenseQuery>;
export type GetLocationsQueryResult = Apollo.QueryResult<GetLocationsQuery, GetLocationsQueryVariables>;
export const GetClientsDocument = gql`
    query GetClients {
  clients {
    id
    name
    imageUrl
  }
}
    `;

/**
 * __useGetClientsQuery__
 *
 * To run a query within a React component, call `useGetClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClientsQuery(baseOptions?: Apollo.QueryHookOptions<GetClientsQuery, GetClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientsQuery, GetClientsQueryVariables>(GetClientsDocument, options);
      }
export function useGetClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientsQuery, GetClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientsQuery, GetClientsQueryVariables>(GetClientsDocument, options);
        }
export function useGetClientsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetClientsQuery, GetClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetClientsQuery, GetClientsQueryVariables>(GetClientsDocument, options);
        }
export type GetClientsQueryHookResult = ReturnType<typeof useGetClientsQuery>;
export type GetClientsLazyQueryHookResult = ReturnType<typeof useGetClientsLazyQuery>;
export type GetClientsSuspenseQueryHookResult = ReturnType<typeof useGetClientsSuspenseQuery>;
export type GetClientsQueryResult = Apollo.QueryResult<GetClientsQuery, GetClientsQueryVariables>;
export const GetClientDocument = gql`
    query GetClient {
  client {
    clientId
    clientImageUrl
    clientName
    userEmail
    userId
    userName
  }
}
    `;

/**
 * __useGetClientQuery__
 *
 * To run a query within a React component, call `useGetClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClientQuery(baseOptions?: Apollo.QueryHookOptions<GetClientQuery, GetClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientQuery, GetClientQueryVariables>(GetClientDocument, options);
      }
export function useGetClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientQuery, GetClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientQuery, GetClientQueryVariables>(GetClientDocument, options);
        }
export function useGetClientSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetClientQuery, GetClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetClientQuery, GetClientQueryVariables>(GetClientDocument, options);
        }
export type GetClientQueryHookResult = ReturnType<typeof useGetClientQuery>;
export type GetClientLazyQueryHookResult = ReturnType<typeof useGetClientLazyQuery>;
export type GetClientSuspenseQueryHookResult = ReturnType<typeof useGetClientSuspenseQuery>;
export type GetClientQueryResult = Apollo.QueryResult<GetClientQuery, GetClientQueryVariables>;
export const GetAlarmsHistoryDocument = gql`
    query GetAlarmsHistory($locationId: UUID!, $slotName: String) {
  alarmsHistory(locationId: $locationId, slotName: $slotName) {
    description {
      descriptionArguments
      descriptionCode
    }
    status
    id
    timestamp
    type
  }
}
    `;

/**
 * __useGetAlarmsHistoryQuery__
 *
 * To run a query within a React component, call `useGetAlarmsHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlarmsHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlarmsHistoryQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      slotName: // value for 'slotName'
 *   },
 * });
 */
export function useGetAlarmsHistoryQuery(baseOptions: Apollo.QueryHookOptions<GetAlarmsHistoryQuery, GetAlarmsHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAlarmsHistoryQuery, GetAlarmsHistoryQueryVariables>(GetAlarmsHistoryDocument, options);
      }
export function useGetAlarmsHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAlarmsHistoryQuery, GetAlarmsHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAlarmsHistoryQuery, GetAlarmsHistoryQueryVariables>(GetAlarmsHistoryDocument, options);
        }
export function useGetAlarmsHistorySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAlarmsHistoryQuery, GetAlarmsHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAlarmsHistoryQuery, GetAlarmsHistoryQueryVariables>(GetAlarmsHistoryDocument, options);
        }
export type GetAlarmsHistoryQueryHookResult = ReturnType<typeof useGetAlarmsHistoryQuery>;
export type GetAlarmsHistoryLazyQueryHookResult = ReturnType<typeof useGetAlarmsHistoryLazyQuery>;
export type GetAlarmsHistorySuspenseQueryHookResult = ReturnType<typeof useGetAlarmsHistorySuspenseQuery>;
export type GetAlarmsHistoryQueryResult = Apollo.QueryResult<GetAlarmsHistoryQuery, GetAlarmsHistoryQueryVariables>;
export const GetAlarmsDocument = gql`
    query GetAlarms($locationId: UUID!, $isAcknowledged: Boolean) {
  alarms(locationId: $locationId, isAcknowledged: $isAcknowledged) {
    description {
      descriptionArguments
      descriptionCode
    }
    id
    isActive
    timestamp
    isAcknowledged
    type
  }
}
    `;

/**
 * __useGetAlarmsQuery__
 *
 * To run a query within a React component, call `useGetAlarmsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlarmsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlarmsQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      isAcknowledged: // value for 'isAcknowledged'
 *   },
 * });
 */
export function useGetAlarmsQuery(baseOptions: Apollo.QueryHookOptions<GetAlarmsQuery, GetAlarmsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAlarmsQuery, GetAlarmsQueryVariables>(GetAlarmsDocument, options);
      }
export function useGetAlarmsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAlarmsQuery, GetAlarmsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAlarmsQuery, GetAlarmsQueryVariables>(GetAlarmsDocument, options);
        }
export function useGetAlarmsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAlarmsQuery, GetAlarmsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAlarmsQuery, GetAlarmsQueryVariables>(GetAlarmsDocument, options);
        }
export type GetAlarmsQueryHookResult = ReturnType<typeof useGetAlarmsQuery>;
export type GetAlarmsLazyQueryHookResult = ReturnType<typeof useGetAlarmsLazyQuery>;
export type GetAlarmsSuspenseQueryHookResult = ReturnType<typeof useGetAlarmsSuspenseQuery>;
export type GetAlarmsQueryResult = Apollo.QueryResult<GetAlarmsQuery, GetAlarmsQueryVariables>;
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useAuth } from "auth/useAuth";
import Barometer from "components/Barometer/Barometer";
import Borehole from "components/Borehole/Borehole";
import GroundPump from "components/GroundPump/GroundPump";
import Pipes from "components/Pipes/Pipes";
import PumpInBorehole from "components/PumpInBorehole/PumpInBorehole";
import Reservoir from "components/Reservoir/Reservoir";
import Voltage from "components/Voltage/Voltage";
import {
  ComponentDto,
  ComponentLogicalNameEnum,
  useGetDashboardQuery,
} from "graphql/main";

const Container = styled.div`
  padding: 0 0 1rem;
  position: absolute;
  width: 43.4rem;
  @media (max-width: 750px) {
    transform: scale(0.7);
    position: absolute;
    top: -2rem;
    padding-bottom: 0rem;
    width: 43.4rem;
    z-index: 18;
  }
`;

const StyledNoData = styled.h1`
  margin-top: 5rem;
  color: white;
`;
const MainDashboard: React.FC = () => {
  const { locationId } = useAuth();
  const [pollInterval, setPollInterval] = useState(10000);
  const { t } = useTranslation();
  const { data: dashboardData } = useGetDashboardQuery({
    pollInterval: pollInterval,
    variables: { locationId: locationId },
  });
  document.body.style.backgroundColor = "#2e7eb7";

  useEffect(() => {
    if (dashboardData) {
      setPollInterval(dashboardData.dashboard.sendInterval * 1000);
    }
  }, [dashboardData, setPollInterval]);

  const transformComponents = (components?: ComponentDto[] | null) =>
    components?.reduce((acc: any, component) => {
      if (component.componentName)
        acc[component.componentName as string] = component.value;

      return acc;
    }, {});
  const transformedComponents = transformComponents(
    dashboardData?.dashboard?.components as ComponentDto[],
  );

  let reservoirCapacity = "2";
  if (transformedComponents) {
    if (
      parseFloat(
        transformedComponents[ComponentLogicalNameEnum.TankLevelMaxLimit],
      ) > 0
    ) {
      reservoirCapacity =
        transformedComponents[ComponentLogicalNameEnum.TankLevelMaxLimit];
    }
  }

  return (
    <>
      {transformedComponents ? (
        <Container>
          <Borehole
            lowProtectionLimit={
              transformedComponents[
                ComponentLogicalNameEnum.BoreholeLevelLowProtectionLimit
              ]
            }
            pumpEmergencyStop={
              transformedComponents[ComponentLogicalNameEnum.Pump1EmergencyStop]
            }
            pumpMotorProtection={
              transformedComponents[
                ComponentLogicalNameEnum.Pump1MotorProtection
              ]
            }
            pumpCurrentMeasurement={
              transformedComponents[
                ComponentLogicalNameEnum.Pump1CurrentMeasurement
              ]
            }
            boreHoleMeasurement={
              transformedComponents[
                ComponentLogicalNameEnum.BoreholeLevelMeasurement
              ]
            }
            isBoreHoleLevelLow={
              transformedComponents[ComponentLogicalNameEnum.BoreholeLevelLow]
            }
            capacity={
              transformedComponents[
                ComponentLogicalNameEnum.BoreholeLevelMaxLimit
              ]
            }
            level={
              transformedComponents[ComponentLogicalNameEnum.BoreholeLevel]
            }
          />
          <Pipes
            isPump2Present={
              transformedComponents[ComponentLogicalNameEnum.Pump2Present]
            }
            pump2contactor={
              transformedComponents[ComponentLogicalNameEnum.Pump2Contactor]
            }
            pump1contactor={
              transformedComponents[ComponentLogicalNameEnum.Pump1Contactor]
            }
          />
          <Barometer
            pressureMeasurement={
              transformedComponents[
                ComponentLogicalNameEnum.PressureMeasurement
              ]
            }
            pressureHighLimit={
              transformedComponents[ComponentLogicalNameEnum.PressureHighLimit]
            }
            pressureLowLimit={
              transformedComponents[ComponentLogicalNameEnum.PressureLowLimit]
            }
            barsData={transformedComponents[ComponentLogicalNameEnum.Pressure]}
          />
          <>
            {transformedComponents[ComponentLogicalNameEnum.Pump2Present] !==
              "NO" && (
              <Reservoir
                reservoirLowProtectionLimit={
                  transformedComponents[
                    ComponentLogicalNameEnum.TankLevelLowProtectionLimit
                  ]
                }
                reservoirLowLimit={
                  transformedComponents[
                    ComponentLogicalNameEnum.TankLevelLowLimit
                  ]
                }
                reservoirHighLimit={
                  transformedComponents[
                    ComponentLogicalNameEnum.TankLevelHighLimit
                  ]
                }
                capacity={reservoirCapacity}
                level={
                  transformedComponents[ComponentLogicalNameEnum.TankLevel]
                }
              />
            )}
            {transformedComponents[ComponentLogicalNameEnum.Pump2Present] !==
              "NO" && (
              <GroundPump
                pumpEmergencyStop={
                  transformedComponents[
                    ComponentLogicalNameEnum.Pump2EmergencyStop
                  ]
                }
                pumpMotorProtection={
                  transformedComponents[
                    ComponentLogicalNameEnum.Pump2MotorProtection
                  ]
                }
                pumpCurrentMeasurement={
                  transformedComponents[
                    ComponentLogicalNameEnum.Pump2CurrentMeasurement
                  ]
                }
                amperage={
                  transformedComponents[ComponentLogicalNameEnum.Pump2Current]
                }
              />
            )}
          </>
          <Voltage
            voltageLowValue={
              transformedComponents[ComponentLogicalNameEnum.VoltageLowLimit]
            }
            voltageHighValue={
              transformedComponents[ComponentLogicalNameEnum.VoltageHighLimit]
            }
            voltage={transformedComponents[ComponentLogicalNameEnum.Voltage]}
          />
          <PumpInBorehole
            pumpCurrent={
              transformedComponents[ComponentLogicalNameEnum.Pump1Current]
            }
          />
        </Container>
      ) : (
        <StyledNoData>{t("no_location_data")}</StyledNoData>
      )}
    </>
  );
};

export default MainDashboard;

import styled from "styled-components";
const dangerColor = "#e8977d";
const safeColor = "#a3dc81";
const lowColor = "grey";
export const Container = styled.div`
  position: absolute;
  left: 31.3rem;
  top: 7rem;
  width: 11.2rem;
  z-index: 7;
`;
export const VesselImg = styled.img`
  position: absolute;
  top: -1rem;
  left: 50%;
  right: 50%;
  transform: translate(-50%);
`;

export const VesselErrorImg = styled.img`
  position: absolute;
  top: -1rem;
  left: 50%;
  right: 50%;
  transform: translate(-50%);
  filter: drop-shadow(4px 4px 4px #f65623);
`;
export const OuterElipseImg = styled.img`
  position: absolute;
`;
export const MiddleElipseImg = styled.img`
  position: absolute;
  left: 1.7rem;
  top: 1.7rem;
`;
export const PointImage = styled.img`
  position: absolute;
  left: -0.3rem;
  top: -0.33rem;
  width: 0.6rem;
  height: 0.6rem;
`;
export const InnerElipseImg = styled.img`
  position: absolute;
  left: 1.9rem;
  top: 1.9rem;
`;
export const Arrow = styled.div`
  position: absolute;
  height: 3.65rem;
  background: black;
  top: 0%;
  left: 50%;
  transform-origin: 50% 100%;
  transform: translateX(50%) rotate(0deg);
`;
export const InvisibleCircle = styled.div`
  position: absolute;
  border-radius: 50%;
  top: 2rem;
  left: 2rem;
  height: 7.3rem;
  width: 7.3rem;
  transform: rotate(180deg);
  z-index: 4;
`;

export const MarkerDiv = styled.div`
  position: absolute;
  background: #7c7b7b;
  height: 0.4rem;
  width: 1px;
`;

export const MarkerZero = styled(MarkerDiv)`
  right: 1.4rem;
  top: -0.3rem;
  transform: rotate(30deg);
`;
export const MarkerOne = styled(MarkerDiv)`
  right: -0.2rem;
  top: 1.15rem;
  transform: rotate(60deg);
`;
export const MarkerTwo = styled(MarkerDiv)`
  right: -0.8rem;
  top: 3.4rem;
  transform: rotate(90deg);
`;

export const MarkerThree = styled(MarkerDiv)`
  right: -0.2rem;
  top: 5.6rem;
  transform: rotate(120deg);
`;

export const MarkerFour = styled(MarkerDiv)`
  right: 1.5rem;
  top: 7.2rem;
  transform: rotate(150deg);
`;
export const MarkerFive = styled(MarkerDiv)`
  right: 3.6rem;
  top: 7.7rem;
  transform: rotate(180deg);
`;
export const MarkerSix = styled(MarkerDiv)`
  right: 5.8rem;
  top: 7.2rem;
  transform: rotate(210deg);
`;
export const MarkerSeven = styled(MarkerDiv)`
  right: 7.35rem;
  top: 5.6rem;
  transform: rotate(240deg);
`;
export const MarkerEight = styled(MarkerDiv)`
  right: 8rem;
  top: 3.4rem;
  transform: rotate(270deg);
`;
export const MarkerNine = styled(MarkerDiv)`
  right: 7.3rem;
  top: 1.15rem;
  transform: rotate(300deg);
`;
export const MarkerTen = styled(MarkerDiv)`
  right: 5.7rem;
  top: -0.3rem;
  transform: rotate(330deg);
`;
export const StyledParagraph = styled.p`
  position: absolute;
  font-size: 0.7rem;
  font-weight: 400;
  color: #7c7b7b;
`;
export const NumberZero = styled(StyledParagraph)`
  left: 2.5rem;
  top: 8.8rem;
  z-index: 4;
`;
export const NumberFive = styled(StyledParagraph)`
  top: -0.5rem;
  left: 5.45rem;
  z-index: 4;
`;
export const NumberTen = styled(StyledParagraph)`
  left: 7.7rem;
  top: 8.8rem;
  z-index: 4;
`;

export const PressureNumber = styled.p`
  font-size: 1.6rem;
  font-weight: 700;
  color: #0161ad;
  display: inline;
`;
export const PressureSpan = styled.span`
  position: absolute;
  color: #4f4f4f;
  left: 2.3rem;
  top: 0.2rem;

  font-size: 0.7rem;
`;
export const PressureHeading = styled.h2`
  font-size: 0.8rem;
  font-weight: 300;
  color: #4f4f4f;
  margin: 0rem;
`;

export const TextContainer = styled.div`
  position: absolute;
  top: 3.7rem;
  left: 4rem;
`;

export const VesselHeading = styled.h1`
  position: absolute;
  top: 11.8rem;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.2rem;
  width: 8.3rem;
  text-align: center;
  color: #4f4e4e;
`;

export const NewBarometerOuter = styled.div<{
  $minDanger: number;
  $maxDanger: number;
}>`
  background: conic-gradient(
    transparent 0% 8%,
    ${lowColor} 8% ${(props) => props.$minDanger}%,
    ${safeColor} ${(props) => props.$minDanger}% ${(props) => props.$maxDanger}%,
    ${dangerColor} ${(props) => props.$maxDanger}% 92%,
    transparent 92% 100%
  );
  width: 100%;
  height: 100%;
  border-radius: 1000px;
  padding: 0.2rem;
  box-sizing: border-box;
  &:before {
    content: "";
    position: absolute;
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50px;
    border: 0.254rem none ${dangerColor};
    border-bottom-style: solid;
    top: 2.1358rem;
    right: 7.34rem;
    transform: rotate(-110deg);
  }

  &:after {
    content: "";
    position: absolute;
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50px;
    border: 0.254rem none ${lowColor};
    border-bottom-style: solid;
    top: 2.15rem;
    right: 3.47rem;
    transform: rotate(120deg);
  }
`;

export const NewBarometerWhite = styled.div`
  background: conic-gradient(#dfeaed 0% 5%, white 5% 95%, #dfeaed 95% 100%);
  width: 100%;
  height: 100%;
  border-radius: 1000px;
  box-sizing: border-box;
  padding: 3px;
`;

export const NewBarometerInner = styled.div<{
  $minDanger: number;
  $maxDanger: number;
}>`
  background: conic-gradient(
    transparent 0% 8%,
    ${lowColor} 8% ${(props) => props.$minDanger}%,
    ${safeColor} ${(props) => props.$minDanger}% ${(props) => props.$maxDanger}%,
    ${dangerColor} ${(props) => props.$maxDanger}% 92%,
    transparent 92% 100%
  );
  width: 100%;
  height: 100%;
  border-radius: 1000px;
  box-sizing: border-box;
  padding: 0.2rem;
`;
export const InnerWhite = styled.div`
  background: conic-gradient(#dfeaed 0% 5%, white 5% 95%, #dfeaed 95% 100%);
  width: 100%;
  height: 100%;
  border-radius: 1000px;
  box-sizing: border-box;
  padding: 0.5rem;
`;
export const InnerBluish = styled.div`
  background: #dfeaed;
  width: 100%;
  height: 100%;
  border-radius: 1000px;
  box-sizing: border-box;
  padding: 5px;
`;
export const OuterWhite = styled.div`
  position: absolute;
  top: 0;
  left: -0.1rem;
  width: 11.4rem;
  height: 11.4rem;
  border-radius: 1000px;
  box-sizing: border-box;
  padding: 1.74rem;
  background: conic-gradient(#dfeaed 0% 5%, white 5% 95%, #dfeaed 95% 100%);
  rotate: 180deg;
  z-index: 3;
`;

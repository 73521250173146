import styled from "styled-components";

export const Container = styled.div`
  padding: 0 0 1rem;
  width: 43.4rem;
  margin-top: 7rem;
  margin-inline: auto;
  height: fit-content;

  @media (max-width: 750px) {
    transform: scale(0.7);
    padding-bottom: 0rem;
    width: 43.4rem;
    position: absolute;
    top: -4rem;
    z-index: 18;
  }
`;

export const Heading = styled.h1`
  white-space: nowrap;
  color: white;
  font-size: 2rem;
  margin-top: -2rem;

  @media (max-width: 500px) {
    margin-top: -4rem;
  }
`;

export const StyledButton = styled.button<{ $isActive: boolean }>`
  cursor: pointer;
  border: ${(props) =>
    props.$isActive ? "2px solid #1060b1" : "2px solid #9fd5f1"};
  border-radius: 5px;
  height: fit-content;
  padding-block: 0.5rem;
  padding-inline: 1rem;
  font-size: 1.2rem;
  background-color: ${(props) => (props.$isActive ? "#b3d8ee" : "#2e7eb7")};
  color: ${(props) => (props.$isActive ? "#1060b1" : "white")};
  margin-top: -2rem;

  @media (max-width: 500px) {
    margin-top: -4rem;
  }
`;

export const AlarmsContainer = styled.div`
  padding: 1rem;
  background-color: #27699a;
  border-radius: 5px;
  width: 100%;
  height: 60vh;
  overflow-y: scroll;
  @media (max-width: 500px) {
    height: 80vw;
  }
  @media (orientation: landscape) {
    height: 60vh;
  }
`;

export const Alarm = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #234c69;
`;

export const AlarmName = styled.h2`
  color: white;
  font-size: 16px;
  width: 70%;
`;

export const AlarmTime = styled.p`
  color: white;
`;

export const AlarmDate = styled.p`
  color: #c3c2c3;
`;

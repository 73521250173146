import pumpContainer from "assets/Rectangle 482.svg";
import boreholeOuterRectangle from "assets/Rectangle 496.svg";

import styles from "./Borehole.module.css";
import Units from "../Units/Units";
import WaterScale from "../WaterScale/WaterScale";

const Borehole: React.FC<{
  lowProtectionLimit: string;
  boreHoleMeasurement: string;
  isBoreHoleLevelLow: string;
  capacity: string;
  level: string;
  pumpCurrentMeasurement: string;
  pumpEmergencyStop: string;
  pumpMotorProtection: string;
}> = ({
  lowProtectionLimit,
  capacity,
  level,
  isBoreHoleLevelLow,
  boreHoleMeasurement,
  pumpCurrentMeasurement,
  pumpEmergencyStop,
  pumpMotorProtection,
}) => {
  const errorColor = "#F65623";
  let levelNumber = 0;
  const isError = boreHoleMeasurement !== "OK" || isBoreHoleLevelLow === "YES";
  const isPumpError =
    pumpCurrentMeasurement !== "OK" ||
    pumpEmergencyStop !== "NO" ||
    pumpMotorProtection !== "OK";
  if (level) {
    levelNumber = parseFloat(level);
  }
  const levelIsCritical = (levelNumber / parseFloat(capacity)) * 100 < 30;
  const percentageInBoreHole = 100; /* hardcoded for now */
  (percentageInBoreHole * 8.3) / 100; /* 8.3 full height in rem */

  return (
    <div className={styles.container}>
      <h2 className={styles.boreholeHeader}>Сондаж</h2>
      <div className={styles.inner}>
        <Units
          error={levelIsCritical}
          right={0.5}
          top={0.5}
          value={level}
          unit="m"
          heading="Воден стълб"
        />
        <div
          style={isError ? { borderColor: errorColor } : {}}
          className={styles.innerUpper}
        />
        <div
          style={isError ? { borderColor: errorColor } : {}}
          className={styles.innerLower}
        />
      </div>
      <img className={styles.outer} src={boreholeOuterRectangle} alt="" />
      <div className={styles.pump}>
        <img src={pumpContainer} alt="" />
        <div
          className={
            isPumpError
              ? `${styles.pumpUpper} ${styles.pumpError}`
              : `${styles.pumpUpper}`
          }
        />
        <div
          className={
            isPumpError
              ? `${styles.pumpMiddle} ${styles.pumpError}`
              : `${styles.pumpMiddle}`
          }
        />
        <div
          className={
            isPumpError
              ? `${styles.pumpLower} ${styles.pumpError}`
              : `${styles.pumpLower}`
          }
        />
      </div>
      <WaterScale
        low={lowProtectionLimit}
        percentage={(levelNumber / parseFloat(capacity)) * 100}
        capacity={capacity}
      />
    </div>
  );
};

export default Borehole;

import "./App.css";
import { useEffect, useState } from "react";

import { ApolloProvider } from "@apollo/client";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";

import { AuthProvider } from "auth/useAuth";
import { apolloClient } from "config/apollo-client";

import PageLayout from "./components/PageLayout";
import AuthGuard from "./guards/AuthGuard";
import AllRoutes from "./screens/AllRoutes";
import Routing from "./screens/Routing";
import i18n from "../src/i18n";

const App = () => {
  const publicRoutes = [AllRoutes.Login];
  const initialOrientation =
    window.innerWidth > window.innerHeight ? "landscape" : "portrait";

  const [orientation, setOrientation] = useState(initialOrientation);

  useEffect(() => {
    // Function to update orientation state based on device orientation
    const handleOrientationChange = () => {
      const initialOrientation = window.matchMedia("(orientation: portrait)")
        .matches
        ? "landscape"
        : "portrait";
      setOrientation(initialOrientation);
    };

    window.addEventListener("orientationchange", handleOrientationChange);

    window.onload = handleOrientationChange;

    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);
  const isPortrait = orientation === "portrait";
  const isMobile = window.innerWidth < 750;

  return (
    <ApolloProvider client={apolloClient}>
      <I18nextProvider i18n={i18n}>
        <AuthProvider>
          <BrowserRouter>
            <AuthGuard publicRoutes={publicRoutes}>
              <PageLayout isPortrait={isPortrait} isMobile={isMobile}>
                <div className="container">
                  <Routing isPortrait={isPortrait} />
                </div>
              </PageLayout>
            </AuthGuard>
          </BrowserRouter>
        </AuthProvider>
      </I18nextProvider>
    </ApolloProvider>
  );
};

export default App;

import { FC, useEffect, useState } from "react";

import { Col, Form, Input, message, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import logo from "assets/hydrokraft_logo.svg";
import { useAuth } from "auth/useAuth";
import restApiRoutes from "config/restApiRoutes";
import { login, passExpiredHandler } from "utils/authorizationUtils";

import {
  AuthButton,
  AuthButtonsContainer,
  AuthForm,
  AuthFormContainer,
  AuthHeader,
  AuthHeaderContainer,
} from "./styles";
import AllRoutes from "../AllRoutes";

const Login: FC = () => {
  const { t } = useTranslation("translation");
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const { token, setToken } = useAuth();
  type FormValues = {
    email: string;
    password: string;
  };

  const onFinish = async (values: FormValues | any) => {
    setLoading(true);

    await fetch(restApiRoutes.AUTH_LOGIN, {
      body: JSON.stringify({
        email: values.email,
        password: values.password,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    })
      .then(async (response: { json: () => any; ok: any }) => {
        const data = await response.json();
        if (!response.ok) {
          return await Promise.reject(data);
        }

        const token = data.token;
        setToken(token);
        const refreshToken = data.refreshToken;

        login(token, refreshToken);
      })
      .catch(
        (err: {
          errors: { PASSWORD_EXPIRED: any[]; WRONG_EMAIL_OR_PASSWORD: any };
        }) => {
          setLoading(false);

          if (err.errors.WRONG_EMAIL_OR_PASSWORD !== undefined)
            message.error(t("wrong_email_or_password"));

          if (err?.errors?.PASSWORD_EXPIRED) {
            const token = err?.errors?.PASSWORD_EXPIRED[0];
            const refreshToken = err?.errors?.PASSWORD_EXPIRED[1];
            setToken(token);
            passExpiredHandler(true);
            login(token, refreshToken);
          }
        },
      );
  };

  useEffect(() => {
    if (token) {
      setLoading(false);
      navigate(AllRoutes.MainDashboard);
    }
  }, [navigate, token]);

  return (
    <AuthFormContainer>
      <AuthHeaderContainer>
        <img src={logo} width={300} height={120} alt="logo" />
        <AuthHeader>Login</AuthHeader>
      </AuthHeaderContainer>
      <Row style={{ width: "100%" }}>
        <Col
          xs={{ offset: 2, span: 20 }}
          md={{ offset: 8, span: 8 }}
          lg={{ offset: 10, span: 4 }}
        >
          <AuthForm
            layout={"vertical"}
            name="basic"
            labelWrap
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              name="email"
              rules={[
                {
                  message: t("invalid_email"),
                  required: true,
                  type: "email",
                },
              ]}
            >
              <Input
                type="email"
                placeholder={t("enter_email")}
                size="large"
                autoComplete="username"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  message: t("invalid_password"),
                  required: true,
                },
              ]}
            >
              <Input.Password
                placeholder={t("password")}
                size="large"
                autoComplete="current-password"
              />
            </Form.Item>
            <AuthButtonsContainer>
              <AuthButton
                htmlType="submit"
                title={t("login")}
                type="primary"
                loading={loading}
              >
                {t("login")}
              </AuthButton>
            </AuthButtonsContainer>
          </AuthForm>
        </Col>
      </Row>
    </AuthFormContainer>
  );
};

export default Login;

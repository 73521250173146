import styled from "styled-components";

export const Container = styled.div<{ right?: number; top?: number }>`
  position: absolute;
  right: ${(props) => (props.right ? props.right + "rem" : "0rem")};
  top: ${(props) => (props.top ? props.top + "rem" : "1rem")};
  z-index: 5;
  display: flex;
  justify-content: center;
  width: 5rem;
`;

export const UpperHalfContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Number = styled.p<{ $error?: boolean }>`
  font-size: 1.6rem;
  margin: 0 0 0 -0.3rem;
  font-weight: 700;
  color: ${(props) => (props.$error ? "#F65623" : "#0161ad")};
`;

export const Unit = styled.span`
  color: #4f4f4f;
  margin-left: 0.2rem;
  margin-top: -0.2rem;
  font-size: 0.9rem;
`;

export const Heading = styled.h2`
  font-size: 0.8rem;
  font-weight: 300;
  color: #4f4f4f;
  margin: 0;
  position: absolute;
  top: 1.8rem;
  white-space: nowrap;
`;

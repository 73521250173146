import i18next, { use } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import bg from "./locales/bg/translation.json";
import en from "./locales/en/translation.json";

use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "Български",
    resources: {
      English: { translation: en },
      Български: { translation: bg },
    },
    returnNull: false,
  });
export default i18next;
